<style lang="sass">
.vue-tel-input
  height: 56px
  border-radius: 4px !important
</style>

<template lang="pug">
  v-card
    v-card-title(v-if="selectedCategory === null") Adicionar Categoria
    v-card-title(v-else) Categoria
    v-card-text
      v-form(ref='form1' v-model="valid")
        v-row(justify='center')
          v-col(sm=10)
            span.subtitle-1.font-weight-medium Nome
            v-text-field(outlined single-line v-model="formData.category.name" clearable :rules='[rules.required]' hint='O nome da Categoria')
            span.subtitle-1.font-weight-medium Descrição
            v-text-field(outlined v-model="formData.category.description" single-line :rules='[rules.required]' clearable hint='A descrição da categoria')
            span.subtitle-1.font-weight-medium Grupos
            v-autocomplete(
              outlined
              v-model="formData.category.groups"
              :items="groups"
              :loading="loadingGettingGroups"
              multiple
              chips
              item-text="name"
              item-value="cod"
            )
              template(v-slot:selection='{ item, index }')
                v-chip(v-if='index < 4')
                  span {{ item.name }}
                span.grey--text.caption(v-if='index === 5') (+{{ formData.category.groups.length - 4 }} outros)
            v-checkbox(v-model="selectedAll" @change="selectAllGroups" label="Selecionar todos os grupos" :disabled="loadingGettingGroups")
            span.subtitle-1.font-weight-medium Icone
            v-autocomplete(outlined v-model="formData.category.icon" :items="iconList")
              template(v-slot:item="data")
                  v-divider
                  v-list-item
                    v-list-item-avatar(size="40")
                      v-icon {{data.item}}
                    v-list-item-content {{data.item}}
              template(v-slot:selection="data")
                  v-divider
                  v-list-item
                    v-list-item-avatar(size="20")
                      v-icon {{data.item}}
                    v-list-item-content {{data.item}}
            span.subtitle-1.font-weight-medium Cor do Ícone
            v-autocomplete(outlined v-model="formData.category.color" :items="colorsList" item-text="color" item-value="color")
              template(v-slot:item="data")
                v-divider
                v-list-item
                  v-list-item-avatar(size="40" :color="data.item")
                  v-list-item-content {{data.item}}
              template(v-slot:selection="data")
                v-divider
                v-list-item
                  v-list-item-avatar(size="20" :color="data.item")
                  v-list-item-content {{data.item}}
            template(v-if="selectedCategory === null")
              v-checkbox(@change="saveVisibleInForm" label="Visivel")
              v-checkbox(@change="saveProtectedInForm" label="Protegido")
              v-checkbox(@change="saveCanPostInForm" label="Pode Postar")
      v-spacer
      v-scale-transition
        v-row.justify-end.mr-2
          v-btn.ml-5(@click="remove" outlined color="error" :loading="deleting" v-if="selectedCategory !== null") Deletar
          v-spacer(v-if="selectedCategory !== null")
          v-btn(@click="add" outlined color='primary' :loading="creating" v-if="selectedCategory === null") Concluir cadastro
          v-btn(@click="save" outlined color='primary' :loading="creating" v-else) Salvar
      v-snackbar( v-model="snackbar.enable" :timeout="6000" top right ) {{ snackbar.text }}
        v-btn( color="pink" text @click="snackbar.enable = false" ) Fechar
</template>

<script>
import { mask } from 'vue-the-mask'
import icons from '../assets/icons'
import colors from '../assets/colors'
// import groups from '../api/organizationgroups'
import { mapFields } from 'vuex-map-fields'

import post from '../api/posts'
export default {
  directives: { mask },
  name: 'add-responsible-dialog-body',
  props: {
    data: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    categories: {
      type: Array,
      default: null
    },
    selectedCategory: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      valid: true,
      snackbar: {
        enable: false,
        text: ''
      },
      selectedAll: false,
      loadingGettingGroups: true,
      loading: false,
      iconList: icons,
      deleting: false,
      creating: false,
      colorsList: colors,
      formData: {},
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        passwordRequired: value => !!value || 'Preencha a senha.',
        radio: (value) => {
          return !!value || 'Campo obrigatório'
        }
      }
    }
  },
  async created () {
    if (this.selectedCategory !== null) {
      this.formData.category = this.selectedCategory
    } else {
      this.formData = {
        category: {
          name: '',
          color: null,
          slug: '',
          icon: '',
          description: '',
          visible: 'false',
          protected: 'false',
          canPost: 'false',
          groups: []
        }
      }
    }
    this.loadingGettingGroups = true
    await this.$store.dispatch('groups/list')
    this.loadingGettingGroups = false
    // this.groups = await groups.list()
  },
  computed: {
    ...mapFields('groups', ['groups'])
  },
  methods: {
    selectAllGroups () {
      console.log('toggle select groups ', this.selectedAll)
      if (this.selectedAll === true) {
        this.formData.category.groups = this.groups.map(g => g.cod)
      } else {
        this.formData.category.groups = []
      }
    },
    async remove () {
      this.deleting = true
      try {
        await post.deleteCategory({ category_id: this.selectedCategory.id })
        this.categories.splice(this.index, 1)
        this.snackbar.text = 'Categoria deletada com sucesso'
        this.snackbar.enable = true
        this.$emit('close-dialog')
        this.deleting = false
      } catch (err) {
        this.deleting = false
        this.snackbar.text = 'Ocorreu algum problema'
        this.snackbar.enable = true

        console.error(err)
      }
    },
    async save () {
      if (this.valid) {
        this.creating = true
        try {
          await post.updateCategory({
            category: {
              id: this.formData.category.id,
              name: this.formData.category.name,
              slug: this.formData.category.name.toLowerCase(),
              description: this.formData.category.description,
              icon: this.formData.category.icon,
              color: this.formData.category.color,
              action: this.formData.category.action,
              visible: this.formData.category.visible,
              protected: this.formData.category.protected,
              can_post: this.formData.category.can_post
            },
            groups: this.formData.category.groups
          })
          this.snackbar.text = 'Categoria salva com sucesso'
          this.snackbar.enable = true
          this.$emit('close-dialog')
          this.creating = false
        } catch (err) {
          this.snackbar.text = 'Ocorreu algum problema'
          this.snackbar.enable = true
          this.creating = false

          console.error(err)
        }
      }
    },
    async add () {
      if (this.valid) {
        this.creating = true
        try {
          this.formData.category.slug = this.formData.category.name.toLowerCase()
          var response = await post.createCategory({
            category: {
              id: this.formData.category.id,
              name: this.formData.category.name,
              slug: this.formData.category.name.toLowerCase(),
              description: this.formData.category.description,
              icon: this.formData.category.icon,
              color: this.formData.category.color,
              action: this.formData.category.action,
              visible: this.formData.category.visible,
              protected: this.formData.category.protected,
              can_post: this.formData.category.can_post
            },
            groups: this.formData.category.groups
          })
          this.categories.push(response.data)
          this.creating = false
          this.snackbar.text = 'Categoria criada com sucesso'
          this.snackbar.enable = true
          this.$emit('close-dialog')
        } catch (err) {
          this.snackbar.text = 'Ocorreu algum problema'
          this.snackbar.enable = true
          this.creating = false
        }
      }
    },
    saveVisibleInForm (value) {
      if (value) {
        this.formData.category.visible = 'true'
      } else {
        this.formData.category.visible = 'false'
      }
    },
    saveProtectedInForm (value) {
      if (value) {
        this.formData.category.protected = 'true'
      } else {
        this.formData.category.protected = 'false'
      }
    },
    saveCanPostInForm (value) {
      if (value) {
        this.formData.category.canPost = 'true'
      } else {
        this.formData.category.canPost = 'false'
      }
    }
  }
}
</script>
