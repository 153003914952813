<template lang="pug">
  v-navigation-drawer#appDrawer(:mobile-break-point='960' clipped v-model='drawer' app)
    perfect-scrollbar.drawer-menu--scroll()
      v-list(expand nav)
        template(v-for='(item, i) in menuItens')
          // group with subitems
          v-list-group(v-if='item.items' :key='item.name'  :group='item.group' :prepend-icon='item.icon' no-action='no-action')
            v-list-item(slot='activator' ripple='ripple')
              v-list-item-content
                v-list-item-title() oi
            template(v-for='(subItem, i) in item.items')
              // sub group
              v-list-group(v-if='subItem.items' :key='subItem.name' :group='subItem.group' sub-group='sub-group')
                v-list-item(slot='activator' ripple='ripple')
                  v-list-item-content
                    v-list-item-title {{ subItem.title }}
                v-list-item(v-for='(grand, i) in subItem.children' :key='i' :to='genChildTarget(item, grand)' :href='grand.href' ripple='ripple')
                  v-list-item-content
                    v-list-item-title {{ grand.title }}
              // child item
              v-list-item(v-else :key='i' :to='genChildTarget(item, subItem)' :href='subItem.href' :disabled='subItem.disabled' :target='subItem.target' ripple='ripple')
                v-list-item-content
                  v-list-item-title
                    span {{ subItem.title }}
                // <v-circle class="white--text pa-0 circle-pill" v-if="subItem.badge" color="red" disabled="disabled">{{ subItem.badge }}</v-circle>
                v-list-item-action(v-if='subItem.action')
                  v-icon(:class="[subItem.actionClass || 'success--text']") {{ subItem.action }}
          v-subheader(v-else-if='item.header' :key='i' ) {{ item.header }}
          v-divider(v-else-if='item.divider' :key='i' )
          // top-level link
          v-list-item(
            v-else
            :href='item.href'
            ripple='ripple'
            :disabled='item.disabled'
            :target='item.target'
            rel='noopener'
            :key='item.name'
            @click="goToTarget(item)"
          )
            v-list-item-action(v-if='item.icon' class='blue-color')
              v-icon {{ item.icon }}
            v-list-item-content(class='blue-color' color="secondary")
              v-row.align-center(v-if="item.notificationCount")
                v-col.py-0.pr-0(cols="9")
                  v-list-item-title {{item.title}}
                v-col.pa-0.pl-2(cols="3")
                  v-spacer
                  v-avatar(color="indigo" size="20")
                    span.white--text.caption {{item.notificationCount}}
              v-list-item-title(v-else) {{item.title}}
            v-list-item-action(v-if='item.subAction')
              v-icon.success--text {{ item.subAction }}
</template>
<script>
import menu from '@/api/menu'
import organization from '@/api/organization'

export default {
  name: 'app-drawer',
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      appName: process.env.VUE_APP_APP_DISPLAY_NAME,
      menuItens: [],
      scrollSettings: {
        maxScrollbarLength: 160
      }
    }
  },
  computed: {
    drawer: {
      get () {
        return this.$store.getters['app/drawer']
      },
      set (v) {
        if (v !== this.$store.getters['app/drawer']) {
          this.$store.commit('app/drawerToogle')
        }
      }
    }
  },
  async created () {
    this.menuItens = await menu.get()
    console.timeEnd('listingMenu')
    var notifications = (await organization.notifications()).data.notifications
    for (let notification of notifications) {
      for (let item of this.menuItens) {
        if (notification.item_id === item.id) {
          item.notificationCount = notification.count
        }
      }
    }
  },
  methods: {
    goToTarget (item) {
      const dynamicComponentPath = item.dynamic_component_path

      if (dynamicComponentPath) {
        this.$router.push({
          name: 'dynamic',
          params: {
            dynamicComponentPath: dynamicComponentPath
          }
        })
      } else {
        if (item.name === 'students' || item.name === 'parent' || item.name === 'professors') {
          const namesMap = {
            'professors': ['prof_supervised', 'prof'],
            'parent': ['parent'],
            'students': ['aluno']
          }
          this.$router.push({
            name: 'users',
            query: {
              types: namesMap[item.name].join(',')
            }
          })
        } else {
          this.$router.push({
            name: item.name
          })
        }
      }
    },
    genChildTarget (item, subItem) {
      if (subItem.href) return
      if (subItem.component) {
        return {
          name: subItem.component
        }
      }
      return { name: `${item.group}/${(subItem.name)}` }
    }
  }
}
</script>

<style lang="sass">
// .blue-color
//   color: #073a56
// .v-navigation-drawer
//   padding-top: 64px !important

// @media (max-width: 960px)
//   .v-navigation-drawer
//     padding-top: 48px !important

// #appDrawer
//   overflow: hidden
//   .drawer-menu--scroll
//     height: calc(100vh - 48px)
//     overflow: auto
//   .down
//     margin-bottom: 1%
</style>
