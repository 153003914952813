<style lang="sass">
.vue-tel-input
  height: 56px
  border-radius: 4px !important
</style>

<template lang="pug">
  v-card
    VuePerfectScrollbar
      v-card-title(v-if="index === null") Adicionar Contato
      v-card-title(v-else) Contato
      v-card-text
        v-form(ref='form1' v-model="valid" v-if="index === null")
          v-row(justify='center')
            v-col(sm=10)
              v-radio-group.mt-1(v-model="formData.type" :rules='[rules.radio]')
                v-row.align-center()
                  v-col
                    v-radio(label="Telefone" value="phone")
                  v-col
                    v-radio(label="Email" value="mail")
                  v-col
                    v-radio(label="Link" value="link")
              v-checkbox.mt-1(v-model="isSocialMedia" label="Midia Social")
              span.subtitle-1.font-weight-medium Titulo
              v-text-field(outlined single-line v-model="formData.title" clearable :rules='[rules.required]' hint='O titulo, em contato seria o nome da pessoa')
              span.subtitle-1.font-weight-medium Texto a ser exibido
              v-text-field(outlined v-model="formData.display" single-line :rules='[rules.required]' clearable hint='O que será mostrado para o usuário')
              span.subtitle-1.font-weight-medium Valor do botão
              v-text-field(outlined v-model="formData.subtitle" single-line :rules='[rules.required]' clearable hint='O valor que será guardado, como o numero e o link para o site')
              span.subtitle-1.font-weight-medium Icone
              v-autocomplete(outlined v-model="formData.icon" :items="iconList")
                template(v-slot:item="data")
                    v-divider
                    v-list-item
                      v-list-item-avatar(size="40")
                        v-icon {{data.item}}
                      v-list-item-content {{data.item}}
                template(v-slot:selection="data")
                    v-divider
                    v-list-item
                      v-list-item-avatar(size="20")
                        v-icon {{data.item}}
                      v-list-item-content {{data.item}}
              span.subtitle-1.font-weight-medium Cor do Ícone
              v-autocomplete(outlined v-model="formData.color" :items="colorsList" item-text="color" item-value="color")
                template(v-slot:item="data")
                  v-divider
                  v-list-item
                    v-list-item-avatar(size="40" :color="data.item")
                    v-list-item-content {{data.item}}
                template(v-slot:selection="data")
                  v-divider
                  v-list-item
                    v-list-item-avatar(size="20" :color="data.item")
                    v-list-item-content {{data.item}}
        v-form(ref='form1' v-model="valid" v-else-if="showingSocialMedia")
          v-row(justify='center')
            v-col(sm=10)
              v-radio-group.mt-1(v-model="formContact.socialnetworks[index].type" :rules='[rules.radio]')
                v-row.align-center()
                  v-col
                    v-radio(label="Telefone" value="phone")
                  v-col
                    v-radio(label="Email" value="mail")
                  v-col
                    v-radio(label="Link" value="link")
              span.subtitle-1.font-weight-medium Titulo
              v-text-field(outlined single-line v-model="formContact.socialnetworks[index].title" clearable :rules='[rules.required]' hint='O titulo, em contato seria o nome da pessoa')
              span.subtitle-1.font-weight-medium Display
              v-text-field(outlined v-model="formContact.socialnetworks[index].display" single-line :rules='[rules.required]' clearable hint='O que será mostrado para o usuário')
              span.subtitle-1.font-weight-medium Valor
              v-text-field(outlined v-model="formContact.socialnetworks[index].subtitle" single-line :rules='[rules.required]' clearable hint='O valor que será guardado, como o numero e o link para o site')
              span.subtitle-1.font-weight-medium Icone
              v-autocomplete(outlined v-model="formContact.socialnetworks[index].icon" :items="iconList")
                template(v-slot:item="data")
                    v-divider
                    v-list-item
                      v-list-item-avatar(size="40")
                        v-icon {{data.item}}
                      v-list-item-content {{data.item}}
                template(v-slot:selection="data")
                    v-divider
                    v-list-item
                      v-list-item-avatar(size="20")
                        v-icon {{data.item}}
                      v-list-item-content {{data.item}}
              span.subtitle-1.font-weight-medium Cor do Ícone
              v-autocomplete(outlined v-model="formContact.socialnetworks[index].color" :items="colorsList" item-text="color" item-value="color")
                template(v-slot:item="data")
                  v-divider
                  v-list-item
                    v-list-item-avatar(size="40" :color="data.item")
                    v-list-item-content {{data.item}}
                template(v-slot:selection="data")
                  v-divider
                  v-list-item
                    v-list-item-avatar(size="20" :color="data.item")
                    v-list-item-content {{data.item}}
        v-form(ref='form1' v-model="valid" v-else)
          v-row(justify='center')
            v-col(sm=10)
              v-radio-group.mt-1(v-model="formContact.contacts[index].type" :rules='[rules.radio]')
                v-row.align-center()
                  v-col
                    v-radio(label="Telefone" value="phone")
                  v-col
                    v-radio(label="Email" value="mail")
                  v-col
                    v-radio(label="Link" value="link")
              span.subtitle-1.font-weight-medium Titulo
              v-text-field(outlined single-line v-model="formContact.contacts[index].title" clearable :rules='[rules.required]' hint='O titulo, em contato seria o nome da pessoa')
              span.subtitle-1.font-weight-medium Display
              v-text-field(outlined v-model="formContact.contacts[index].display" single-line :rules='[rules.required]' clearable hint='O que será mostrado para o usuário')
              span.subtitle-1.font-weight-medium Valor
              v-text-field(outlined v-model="formContact.contacts[index].subtitle" single-line :rules='[rules.required]' clearable hint='O valor que será guardado, como o numero e o link para o site')
              span.subtitle-1.font-weight-medium Icone
              v-autocomplete(outlined v-model="formContact.contacts[index].icon" :items="iconList")
                template(v-slot:item="data")
                    v-divider
                    v-list-item
                      v-list-item-avatar(size="40")
                        v-icon {{data.item}}
                      v-list-item-content {{data.item}}
                template(v-slot:selection="data")
                    v-divider
                    v-list-item
                      v-list-item-avatar(size="20")
                        v-icon {{data.item}}
                      v-list-item-content {{data.item}}
              span.subtitle-1.font-weight-medium Cor do Ícone
              v-autocomplete(outlined v-model="formContact.contacts[index].color" :items="colorsList" item-text="color" item-value="color")
                template(v-slot:item="data")
                  v-divider
                  v-list-item
                    v-list-item-avatar(size="40" :color="data.item")
                    v-list-item-content {{data.item}}
                template(v-slot:selection="data")
                  v-divider
                  v-list-item
                    v-list-item-avatar(size="20" :color="data.item")
                    v-list-item-content {{data.item}}
        v-spacer
        v-scale-transition
          v-row.justify-end.mr-2
            v-btn.ml-5(@click="remove" outlined color="error" :loading="deleting" v-if="index !== null") Deletar
            v-spacer(v-if="index !== null")
            v-btn(@click="add" outlined color='primary' :loading="loading" v-if="index === null") Concluir cadastro
            v-btn(@click="save" outlined color='primary' :loading="loading" v-else) Salvar
        v-snackbar( v-model="snackbar.enable" :timeout="6000" top right ) {{ snackbar.text }}
          v-btn( color="pink" text @click="snackbar.enable = false" ) Fechar
</template>

<script>
import { mask } from 'vue-the-mask'
import icons from '../assets/icons'
import colors from '../assets/colors'
import post from '../api/organization.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  directives: { mask },
  name: 'add-responsible-dialog-body',
  components: {
    VuePerfectScrollbar
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    showingSocialMedia: {
      type: Boolean,
      default: null
    },
    contacts: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      valid: true,
      snackbar: {
        enable: false,
        text: ''
      },
      loading: false,
      iconList: icons,
      deleting: false,
      colorsList: colors,
      formContact: Object.assign({}, this.contacts),
      isSocialMedia: this.showingSocialMedia,
      formData: {
        title: '',
        type: null,
        subtitle: '',
        icon: ''
      },
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        passwordRequired: value => !!value || 'Preencha a senha.',
        radio: (value) => {
          return !!value || 'Campo obrigatório'
        }
      },
      email: value => {
        if (value && value.length > 0) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        } else {
          return true
        }
      }
    }
  },
  async created () {
  },
  methods: {
    async remove () {
      if (this.valid) {
        this.deleting = true
        if (this.isSocialMedia) {
          this.formContact.socialnetworks.splice(this.index, 1)
        } else {
          this.formContact.contacts.splice(this.index, 1)
        }
        try {
          await post.create(JSON.stringify(this.formContact))
          this.snackbar.text = 'Contato salvo com sucesso'
          this.snackbar.enable = true
          this.$emit('close-dialog')
          this.deleting = false
          this.contacts = this.formContact
        } catch (err) {
          console.error(err)
          this.snackbar.text = 'Ocorreu um erro ao registrar'
          this.snackbar.enable = true
          this.deleting = false
        }
      } else {
        this.snackbar.text = 'Por favor preencha todos os campos'
        this.snackbar.enable = true
      }
    },
    async add () {
      if (this.valid) {
        this.loading = true
        if (this.isSocialMedia) {
          this.contacts.socialnetworks.push(this.formData)
        } else {
          this.contacts.contacts.push(this.formData)
        }
        try {
          await post.create(JSON.stringify(this.contacts))
          this.snackbar.text = 'Contato registrado com sucesso'
          this.snackbar.enable = true
          this.$emit('close-dialog')
          this.formData = {}
          this.loading = false
        } catch (err) {
          console.error(err)
          this.snackbar.text = 'Ocorreu um erro ao registrar'
          this.snackbar.enable = true
          this.loading = false
        }
      } else {
        this.snackbar.text = 'Por favor preencha todos os campos'
        this.snackbar.enable = true
      }
    },
    async save () {
      if (this.valid) {
        this.loading = true
        try {
          await post.create(JSON.stringify(this.formContact))
          this.snackbar.text = 'Contato salvo com sucesso'
          this.snackbar.enable = true
          this.$emit('close-dialog')
          this.loading = false
          this.contacts = this.formContact
        } catch (err) {
          console.error(err)
          this.snackbar.text = 'Ocorreu um erro ao registrar'
          this.snackbar.enable = true
          this.loading = false
        }
      } else {
        this.snackbar.text = 'Por favor preencha todos os campos'
        this.snackbar.enable = true
      }
    }
  }
}
</script>
