<template lang="pug">
  v-app-bar(color='primary' clipped-left fixed dark app)
    v-app-bar-nav-icon(@click.stop='handleDrawerToggle' v-if="showingDrawer")
    //-  Notificações... utilizar a collection de notificações do Quoti.. indexar com organizationSlug e userId
      v-badge(
        dot
        color="warning"
        bottom
        offset-y="17"
        offset-x="15")
      v-app-bar-nav-icon(@click.stop='handleDrawerToggle' v-if="showingDrawer")
    v-spacer
    v-text-field(
      v-model="searchText"
      v-on:keyup="debouncedUpdateSearch()"
      label='Pesquisar'
      prepend-inner-icon='search'
      single-line
      solo-inverted
      flat
      hide-details
      shaped
      color="primary"
    )
    v-spacer
    v-btn(v-if="validatePermissions(['posts.filter'])" icon title="Abrir filtro de busca" @click="$store.dispatch('feed/toggleSearchDrawer'); toggleFilter()")
      v-icon filter_list
    v-menu.elevation-1(offset-y origin='center center' :nudge-bottom='14' transition='scale-transition')
    v-menu(offset-y origin='center center' :nudge-bottom='10' transition='scale-transition')
      template(v-slot:activator="{ on }")
        v-btn(icon large text v-on="on")
          v-icon account_circle
        //- v-avatar(size='36px')
          img(:src='user.picture' :alt='user.name')
      v-list(nav).pa-0
        v-list-item(v-for='(item,index) in items' :to='!item.href ? { name: item.name } : null' :href='item.href' @click='item.click' ripple='ripple' :disabled='item.disabled' :target='item.target' rel='noopener' :key='index')
          v-list-item-action(v-if='item.icon')
            v-icon {{ item.icon }}
          v-list-item-content.pr-4
            v-list-item-title {{ item.title }}
</template>
<script>
import { mapState } from 'vuex'
import Permissions from '../utils/permissionsManager'
import _ from 'lodash'

export default {
  name: 'app-toolbar',
  data () {
    return {
      /**
       *
       * Seguindo o mesmo caminho que fiz o menu vir de um JS, esse vetor Items poderia ser importado de um JS tb, e estar vindo do database também...
       *
       */
      selectedGroups: [],
      items: [
        {
          icon: 'account_circle',
          href: '#',
          title: 'Perfil',
          click: (e) => { this.$router.push({ name: 'UserProfile', params: { userId: 'Me' } }) }
        },
        /* {
          icon: 'settings',
          href: '#',
          title: 'Configurações'
        }, */
        {
          icon: 'exit_to_app',
          href: '#',
          title: 'Sair',
          click: (e) => {
            this.$store.dispatch('auth/logout')
          }
        }
      ],
      searchText: null,
      debouncedUpdateSearch: null
    }
  },
  computed: {
    ...mapState(['auth']),
    user () {
      return this.$store.getters['user/userAuthenticated']
    },
    toolbarColor () {
      return this.$vuetify.options.extra.mainNav
    },
    showingDrawer () {
      return this.$store.getters['app/showingDrawer']
    }
  },
  methods: {
    updateSearch (text) {
      this.$store.commit('search/update', text)
    },
    toggleFilter () {
      this.$store.commit('search/toggleFilter')
    },
    handleDrawerToggle () {
      this.$store.commit('app/drawerToogle')
    },
    async logout () {
      try {
        await this.$store.dispatch('auth/logout')
      } catch (err) {
        console.error(err)
      }
    },
    validatePermissions: Permissions.validatePermissions
  },
  created () {
    // clean store
    this.$store.commit('app/updateSearchField', '')

    this.debouncedUpdateSearch = _.debounce(() => {
      this.updateSearch(this.searchText)
    }, 400)
  }
}
</script>

<style lang="sass" scoped>
  // .v-menu__content
  //   left: calc(100vw - 200px) !important
</style>
