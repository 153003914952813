<template lang="pug">
  v-bottom-navigation(app color="primary" :horizontal="this.$vuetify.breakpoint.mdAndUp")
    v-btn(v-for="item in menuItens" :key="item.id" :to='{ name: item.name }' v-if="item.title")
      span {{resolveTitle(item.title)}}
      v-icon {{item.icon}}
          //- v-list-item(v-else :to='!item.href ? { name: item.name } : null' :href='item.href' ripple='ripple' :disabled='item.disabled' :target='item.target' rel='noopener' :key='item.name')
          //-   v-list-item-action(v-if='item.icon' class='blue-color')
          //-     v-icon {{ item.icon }}
          //-   v-list-item-content(class='blue-color' color="secondary")
          //-     v-list-item-title {{item.title}}
          //-   v-list-item-action(v-if='item.subAction')
          //-     v-icon.success--text {{ item.subAction }}
</template>
<script>
import menu from '@/api/menu'

export default {
  name: 'app-bottom',
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuItens: []
    }
  },
  async created () {
    this.menuItens = await menu.get()
    console.log('MENU', this.menuItens)
  },
  methods: {
    resolveTitle (title) {
      var word = title.split(' ')
      return word[0]
    }
  }
}
</script>

<style lang="sass">
// .blue-color
//   color: #073a56
// .v-navigation-drawer
//   padding-top: 64px !important

// @media (max-width: 960px)
//   .v-navigation-drawer
//     padding-top: 48px !important

// #appDrawer
//   overflow: hidden
//   .drawer-menu--scroll
//     height: calc(100vh - 48px)
//     overflow: auto
//   .down
//     margin-bottom: 1%
</style>
